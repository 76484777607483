import convertEmotionToCss from 'utils/convertEmotionToCss';

import { notoSans } from './fonts';

import { textStyles } from 'styles/textStyles';
import theme from 'styles/theme';

export const globalStyling = `
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: ${notoSans.style.fontFamily}, sans-serif;
    -webkit-text-stroke: 0.15pt;
    -webkit-font-smoothing: antialiased;
    min-width: fit-content;
  }

  button {
    font-family: inherit;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  .scChromeData, .scpm {
    display: none !important;
  }

  h1 {
    ${convertEmotionToCss(textStyles.heading1)};
  }

  h2 {
    ${convertEmotionToCss(textStyles.heading2)};
  }

  h3 {
    ${convertEmotionToCss(textStyles.heading3)};
  }

  h4 {
    ${convertEmotionToCss(textStyles.heading4)};
  }

  p {
    ${convertEmotionToCss(textStyles.paragraph)};
  }

  a, button, input, select, option {
    ${convertEmotionToCss(theme.focusIndicatorOnLightBg)}
  }

  // Kendo dropdown styling
  .k-dropdownlist {
    ${convertEmotionToCss(theme.focusIndicatorOnLightBg)};
    background: none;
    border: solid 1px #D1D1D1;
    box-shadow: none;

    .k-input-inner {
      color: ${theme.colors.accent};
      font-weight: ${theme.fontWeights.bold};
      padding-right: 0;
    }

    &:hover {
      border-color: ${theme.colors.accent};
      background-color: ${theme.colors.background};
    },

    .k-button {
      padding: 0;
      background-size: 16px 16px;

      .k-button-icon {
        display: none;
      }
    }
  }

  .k-popup {
    box-shadow: ${theme.boxShadow.centredOverlay};
    color: ${theme.colors.black};
    padding: 8px;

    .k-list-item {
      padding: 8px;

      &:hover {
        background-color: ${theme.colors.background};
      }
    }

    .k-selected {
      background-color: white;
      color: ${theme.colors.black};
      font-weight: ${theme.fontWeights.bold};

      &:hover {
        background-color: ${theme.colors.background};
        color: ${theme.colors.black};
      }
    }
  }
`;
