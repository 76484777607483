import { Theme } from '@emotion/react';

export const breakpoints: Record<string, number> = {
  mobile: 360,
  tablet: 641,
  laptop: 1001,
  desktop: 1201,
  desktopL: 1600,
  desktopXL: 1808,
};

const mediaQuery = Object.entries(breakpoints).reduce(
  (prev, [key, breakpoint]) => ({ ...prev, [key]: `@media (min-width: ${breakpoint}px)` }),
  {} as Record<string, string>,
);

const spacing = (...args: number[]) => {
  const baseSpacing = 4;
  const spacingInPixels = args.map((arg) => arg * baseSpacing + 'px').join(' ');
  return spacingInPixels;
};

const theme: Theme = {
  mediaQuery,
  colors: {
    // brand colors
    primary: '#333F48',
    primaryHover: '#47525A',
    secondary: '#FF5F49',
    secondaryAccess: '#DC3618',
    accent: '#1A4960',
    accentLight: '#EEF8FB',
    accentLightBorder: '#57B6D5',
    // neutrals
    white: '#FFFFFF',
    grey1: '#EBECED',
    grey2: '#D6D8DA',
    grey3: '#C2C5C8',
    grey4: '#ADB2B6',
    grey5: '#5C656D',
    grey6: '#323231',
    black: '#000000',
    //semantic
    success: '#007A78',
    error: '#C1292E',
    // others
    background: '#F7F1EB',
    backgroundBorder: '#D6BB9D',
    scrim: '#333F4880',
    homeHeader: '#821000',
  },
  rags: {
    greenText: '#1C431D',
    greenHover: '#5DD462',
    greenDefault: '#9EE5A1',
    greenLight: '#D9F5DA',
    amberText: '#4B3815',
    amberHover: '#E2B640',
    amberDefault: '#FECF48',
    amberLight: '#FEEDB9',
    redText: '#612B23',
    redHover: '#FEA599',
    redDefault: '#FFC7C0',
    redLight: '#FFE9E6',
  },
  fontSizes: {
    7: '0.4375rem',
    8: '0.5rem',
    10: '0.625rem',
    11: '0.6875rem',
    12: '0.75rem',
    13: '0.8125rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    23: '1.4375rem',
    24: '1.5rem',
    26: '1.625rem',
    29: '1.8125rem',
    32: '2rem',
    36: '2.25rem',
    39: '2.4375rem',
    43: '2.6875rem',
    44: '2.75rem',
    54: '3.375rem',
    57: '3.5625rem',
  },
  lineHeights: {
    body: '150%',
    smallTitleException: '130%',
    interfaceHeading: '125%',
    labelAndContentHeading: '120%',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  boxShadow: {
    centredOverlay: '0px 6px 24px rgba(51, 63, 72, 0.05), 0px 6px 48px rgba(51, 63, 72, 0.15)',
    leftSideOverlay: '6px 0px 24px rgba(51, 63, 72, 0.05), 6px 0px 48px rgba(51, 63, 72, 0.15)',
    rightSideOverlay: '-6px 0px 24px rgba(51, 63, 72, 0.05), -6px 0px 48px rgba(51, 63, 72, 0.15)',
    bottomOverlay:
      '0px -8px 20px 5px rgba(0, 0, 0, 0.05), 0px -4px 4px 0px rgba(0, 0, 0, 0.05), 0px -6px 10px 0px rgba(0, 0, 0, 0.02)',
  },
  spacing,
  focusIndicatorOnLightBg: {
    '&:focus': {
      outline: '4px solid #333F48',
      outlineOffset: '2px',
    },
  },
  focusIndicatorOnDarkBg: {
    '&:focus': {
      outline: '4px solid #FFFFFF',
      outlineOffset: '2px',
    },
  },
  focusIndicatorAccent: {
    '&:focus': {
      outline: '4px solid #57B6D5',
      outlineOffset: '2px',
    },
  },
};

export default theme;
