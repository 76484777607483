const getCssProperty = (objectKey: string) => {
  const cssProperty = objectKey.replace(
    /[A-Z]/g,
    (secondPart) => `-${secondPart[0].toLowerCase()}`,
  );

  return cssProperty;
};

const convertEmotionToCss = (
  style: Record<string, string | number | Record<string, string | number>>,
): string =>
  Object.entries(style)
    .map(([cssObjectKey, cssValue]) => {
      const nestedCss =
        typeof cssValue === 'object' && !Array.isArray(cssValue) && cssValue !== null
          ? cssValue
          : null;

      if (nestedCss) {
        return `${getCssProperty(cssObjectKey)} { ${convertEmotionToCss(nestedCss)} }`;
      }

      return `${getCssProperty(cssObjectKey)}:${cssValue}`;
    })
    .join(';');

export default convertEmotionToCss;
