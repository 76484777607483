import { Global, ThemeProvider } from '@emotion/react';
import '@progress/kendo-theme-default/dist/all.css';
import 'axios-debug-log/enable';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import React from 'react';

import { SharedPageProps } from 'lib/getSharedPageProps';

import { globalStyling } from 'styles/globals';
import theme from 'styles/theme';

interface CustomAppProps extends AppProps {
  pageProps: { session: Session } & SharedPageProps;
}

// react axe set up (should only run clinet-side and locally):
const isServerSideRendered = () => {
  return typeof window === 'undefined';
};

if (process.env.NODE_ENV !== 'production' && !isServerSideRendered()) {
  import('react-dom').then((ReactDOM) => {
    import('@axe-core/react').then((axe) => {
      axe.default(React, ReactDOM, 1000, {});
    });
  });
}

function App({ Component, pageProps: { session, ...pageProps } }: CustomAppProps): JSX.Element {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id='${pageProps.googleTagId}'`}
        nonce={pageProps.nonce}
      />
      <Script id="google-analytics" nonce={pageProps.nonce}>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {
            'analytics_storage': 'denied'
          });
          gtag('js', new Date());
          gtag('config', '${pageProps.googleTagId}');
        `}
      </Script>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyling} />
        <SessionProvider session={session}>
          <Component {...pageProps} />
        </SessionProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
