import { fedra, notoSans } from './fonts';

import theme from 'styles/theme';

type textStyleNames =
  | 'linkStyle'
  | 'display1'
  | 'display2'
  | 'display3'
  | 'display4'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title4'
  | 'baseLarge'
  | 'baseDefault'
  | 'baseSmall'
  | 'label'
  | 'tinyLabel'
  | 'smallTitle1'
  | 'smallTitle2'
  | 'smallTitle3'
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'paragraphLarge'
  | 'paragraph'
  | 'caption';

// base styles

const textBaseStyle = {
  fontFamily: `${notoSans.style.fontFamily}, sans-serif`,
  margin: 0,
  padding: 0,
};

const displayBaseStyle = {
  ...textBaseStyle,
  fontFamily: `${fedra.style.fontFamily}`,
  fontWeight: theme.fontWeights.medium,
  lineHeight: theme.lineHeights.interfaceHeading,
  letterSpacing: '0.02em',
};

const titleBaseStyle = {
  ...textBaseStyle,
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.lineHeights.interfaceHeading,
};

const bodyBaseStyle = {
  ...textBaseStyle,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.body,
};

const labelBaseStyle = {
  ...textBaseStyle,
  lineHeight: theme.lineHeights.labelAndContentHeading,
};

const smallTitleBaseStyle = {
  ...textBaseStyle,
  fontWeight: theme.fontWeights.extraBold,
  lineHeight: theme.lineHeights.body,
  letterSpacing: '0.08em',
  textTransform: 'uppercase',
};

// Interface typography

const display1 = {
  ...displayBaseStyle,
  fontSize: theme.fontSizes[29],
};

const display2 = {
  ...displayBaseStyle,
  fontSize: theme.fontSizes[26],
};

const display3 = {
  ...displayBaseStyle,
  fontSize: theme.fontSizes[23],
};

const display4 = {
  ...displayBaseStyle,
  fontSize: theme.fontSizes[20],
};

const title1 = {
  ...titleBaseStyle,
  fontSize: theme.fontSizes[26],
};

const title2 = {
  ...titleBaseStyle,
  fontSize: theme.fontSizes[23],
};

const title3 = {
  ...titleBaseStyle,
  fontSize: theme.fontSizes[20],
};

const title4 = {
  ...titleBaseStyle,
  fontSize: theme.fontSizes[18],
};

const baseLarge = {
  ...bodyBaseStyle,
  fontSize: theme.fontSizes[18],
};

const baseDefault = {
  ...bodyBaseStyle,
  fontSize: theme.fontSizes[16],
};

const baseSmall = {
  ...bodyBaseStyle,
  fontSize: theme.fontSizes[14],
};

const label = {
  ...labelBaseStyle,
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes[14],
  letterSpacing: '0.06em',
  textTransform: 'uppercase',
};

const tinyLabel = {
  ...labelBaseStyle,
  fontWeight: theme.fontWeights.regular,
  fontSize: theme.fontSizes[12],
  letterSpacing: '0.02em',
};

const smallTitle1 = {
  ...smallTitleBaseStyle,
  fontSize: theme.fontSizes[16],
  lineHeight: theme.lineHeights.smallTitleException,
};

const smallTitle2 = {
  ...smallTitleBaseStyle,
  fontSize: theme.fontSizes[14],
};

const smallTitle3 = {
  ...smallTitleBaseStyle,
  fontSize: theme.fontSizes[13],
};

// Content/editorial typography

const heading1 = {
  ...displayBaseStyle,
  fontSize: theme.fontSizes[44],
  lineHeight: theme.lineHeights.labelAndContentHeading,
  color: theme.colors.secondary,

  [theme.mediaQuery['tablet']]: {
    fontSize: theme.fontSizes[54],
  },
};

const heading2 = {
  ...displayBaseStyle,
  fontSize: theme.fontSizes[32],
  lineHeight: theme.lineHeights.labelAndContentHeading,

  [theme.mediaQuery['tablet']]: {
    fontSize: theme.fontSizes[36],
  },
};

const heading3 = {
  ...displayBaseStyle,
  fontSize: theme.fontSizes[20],
  lineHeight: theme.lineHeights.labelAndContentHeading,

  [theme.mediaQuery['tablet']]: {
    fontSize: theme.fontSizes[24],
  },
};

const heading4 = {
  ...title4,
  lineHeight: theme.lineHeights.labelAndContentHeading,
  letterSpacing: '0.02em',
};

const paragraphLarge = {
  ...baseLarge,
  letterSpacing: '0.02em',
};

const paragraph = {
  ...baseDefault,
  letterSpacing: '0.02em',
};

const caption = {
  ...baseSmall,
  fontSize: theme.fontSizes[12],
  lineHeight: 'inherit',
  [theme.mediaQuery['laptop']]: {
    fontSize: theme.fontSizes[14],
  },
};

const linkStyle = {
  color: theme.colors.secondary,
  fontWeight: theme.fontWeights.bold,
  textDecoration: 'underline',
};

export const textStyles: Record<
  textStyleNames,
  {
    [key: string]: string | number | { [key: string]: string | number };
  }
> = {
  linkStyle,
  display1,
  display2,
  display3,
  display4,
  title1,
  title2,
  title3,
  title4,
  baseLarge,
  baseDefault,
  baseSmall,
  label,
  tinyLabel,
  smallTitle1,
  smallTitle2,
  smallTitle3,
  heading1,
  heading2,
  heading3,
  heading4,
  paragraphLarge,
  paragraph,
  caption,
};
